import { Component, OnInit, Input } from '@angular/core';
import { faCode, faRetweet, faExclamationTriangle, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { faSass } from '@fortawesome/free-brands-svg-icons';
import { HelperService } from '../../../helpers/helper.service';

import { Post } from '../../../models/post';
@Component({
  selector: 'dc-slider-programs',
  templateUrl: './slider-programs.component.html',
  styleUrls: ['./slider-programs.component.scss'],
})
export class SliderProgramsComponent implements OnInit {
  longArrowAltRight = faLongArrowAltRight;
  @Input()
  posts: Post[];

  items = [
    {
      label: 'Economic Empowerment',
      code: `
Through Microfinance and  Livelihood Programmes. Share Foundation works on developing the local communities that suffer from economic and social problems in  a way that  makes all its  interventions  a sustainable tool that is capable of  self-growing and developing.  Share Foundation's strate...`,
      lang: 'html',
      icon: faCode,
    },
    {
      label: 'Social Empowerment',
      code: `
    By Awareness Raising , Education, and Capacity Building. Share Foundation aims at developing the Human Resources in targeted communities  in order to empower individuals to become aware of their communities’ needs  and  finding solutions that can assist the process of communities’ recovery. `,
      lang: 'javascript',
      icon: faSass,
    },
    {
      label: 'Women Empowerment',
      code: `
    Through the Capacity Building Programmes and Training Programmes. Share Foundation plays a role in empowering woman in different domains in order to participate in community development  process through giving them the chance to take part  in the cultural, economic, and social communities`,
      lang: 'javascript',
      icon: faRetweet,
    },
  ];
  currentSlide: number = 0;

  constructor() {}

  ngOnInit() {}

  updateSlider(currentSlide: any) {
    this.currentSlide = currentSlide;
  }

  public onIndexChange(index: number): void {
    this.currentSlide = index;
  }
}
