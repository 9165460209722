<section class="section" id="demos">
  <div class="container-fluid">
    <div class="section-heading text-center">
      <h2>Demo Selection</h2>
      <p class="lead text-secondary">DashCore comes with multiple demos, choose the one you want to give a try.</p>
    </div>

    <div class="row gap-y">
      <div class="col-sm-6 col-md-4" *ngFor="let demo of demos">
        <dc-home-demo [demo]="demo"></dc-home-demo>
      </div>
    </div>
  </div>
</section>
