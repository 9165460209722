<footer class="tp-site-footer">
  <div class="tp-upper-footer">
    <div class="container">
      <div class="row">
        <div class="col col-lg-3 col-md-3 col-sm-6 col-12">
          <div class="widget about-widget">
            <div class="footer-logo widget-title">
              <a href="/assets/img/logo.png"
                ><img width="160px" src="/assets/img/logo.png" alt="logo" /><span></span
              ></a>
            </div>
            <p [innerHTML]="getGlobalThemeOptions().who_we_are"></p>
            <ul>
              <li>

                <a href="{{ getGlobalThemeOptions().facebook }}" target="_blank"><i class="ti-facebook"></i></a>
              </li>
              <li>
                <a href="{{ getGlobalThemeOptions().twitter }}" target="_blank"><i class="ti-twitter-alt"></i></a>
              </li>
              <li>
                <a href="{{ getGlobalThemeOptions().youtube }}" target="_blank"><i class="ti-youtube"></i></a>
              </li>
              <li>
                <a href="{{ getGlobalThemeOptions().linkedin }}" target="_blank"><i class="ti-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col col-lg-2 col-md-3 col-sm-6">
          <div class="widget link-widget">
            <div class="widget-title">
              <h3>{{ 'Useful Links' | translate }}</h3>
            </div>
            <div class="footer-menu" [innerHTML]="getGlobalThemeOptions().footer_menu"></div>
          </div>
        </div>
        <div class="col col-lg-3 col-lg-offset-1 col-md-3 col-sm-6">
          <div class="widget market-widget tp-service-link-widget">
            <div class="widget-title">
              <h3>{{ 'Contact' | translate }}</h3>
            </div>
            <div class="contact-ft">
              <ul>
                <li>
                  <i class="fi flaticon-call"></i
                  ><a href="tel:{{ getGlobalThemeOptions().phone }}">{{ getGlobalThemeOptions().phone }}</a>
                </li>
                <li>
                  <i class="fi flaticon-envelope"></i
                  ><a href="mailto:{{ getGlobalThemeOptions().email }}">{{ getGlobalThemeOptions().email }}</a>
                </li>
                <li><i class="fi flaticon-pin"></i>{{ getGlobalThemeOptions().address }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-6">
          <div class="widget instagram">
            <div class="widget-title">
              <h3>{{ 'Our Projects' | translate }}</h3>
            </div>
            <ul class="d-flex">
              <li *ngFor="let project of projects">
                <a href="/page/project/{{ project.slug }}"><img [src]="getImageBySize(project.image, 'thumb')" alt="{{ project.name }}" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- end container -->
  </div>
  <div class="tp-lower-footer">
    <div class="container">
      <div class="row">
        <div class="col col-xs-12">
          <div class="col-sm">
            <a href="/privacy-policy">{{ 'privacy policy' | translate }}</a
            ><span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span><a href="/about">{{ 'About Us' | translate }}</a>
            <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span><a href="/contact">{{ 'Contact' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

