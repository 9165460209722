import { NgModule } from '@angular/core';
import { SharedModule } from '@app/@shared';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HomeRoutingModule } from './home-routing.module';
import { SlidersModule } from '../../blocks/sliders/sliders.module';
import { HomeComponent } from './components/home/home.component';
import { HomeFeaturesComponent } from './components/home-features/home-features.component';
import { HomeSelectionComponent } from './components/home-selection/home-selection.component';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { HomeColumnsCardsComponent } from './components/home-columns-cards/home-columns-cards.component';
import { OnlinePaymentModule } from '../online-payment/online-payment.module';


import { CorporateModule } from '../corporate/corporate.module';

import { AboutModule } from '../about/about.module';
import { TestimonialsModule } from '../../blocks/testimonials/testimonials.module';


import { HomePurchaseComponent } from './components/home-purchase/home-purchase.component';
import { HomeDemoComponent } from './components/home-demo/home-demo.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  imports: [
    SharedModule,
    ScrollToModule.forRoot(),
    HomeRoutingModule,
    SlidersModule,
    SlickCarouselModule,
    AboutModule,
    CorporateModule,
    TestimonialsModule,
    OnlinePaymentModule,
    // SliderDeveloperModule
  ],
  declarations: [
    HomeComponent,
    HomeFeaturesComponent,
    HomeSelectionComponent,
    HomePurchaseComponent,
    HomeDemoComponent,
    HomeSliderComponent,
    HomeColumnsCardsComponent,
    // HomeFooterComponent
  ],
})
export class HomeModule {}
