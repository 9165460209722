export interface ProjectInterface {
  id: string;
  name: string;
  slug: string;
  summary: string;
  content: string;
  image: string;

  created_at: string;
  updated_at: string;
}
export class Project implements ProjectInterface {
  id: string;
  name: string;
  slug: string;
  summary: string;
  content: string;
  image: string;

  created_at: string;
  updated_at: string;
}
