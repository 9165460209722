import { Component, OnInit } from '@angular/core';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'dc-testimonial-simple-text-left',
  templateUrl: './testimonial-simple-text-left.component.html',
  styleUrls: ['./testimonial-simple-text-left.component.scss'],
})
export class TestimonialSimpleTextLeftComponent implements OnInit {
  quoteLeft = faQuoteLeft;
  constructor() {}

  ngOnInit() {}
}
