import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'media'
})
export class MediaPipe implements PipeTransform {

  transform(value: string,exponent: number): string {
    // 1 - thumbnail 150x150
    // 2 - small
    // 3 - medium
    // 4 - large

    if(exponent === 1)
    return value.split('.').slice(0, -1).join('.')+'-150x150.'+value.substring(value.lastIndexOf(".")+1);

    if(exponent === 2)
    return value.split('.').slice(0, -1).join('.')+'-200x180.'+value.substring(value.lastIndexOf(".")+1);

    if(exponent === 3)
    return value.split('.').slice(0, -1).join('.')+'-400x360.'+value.substring(value.lastIndexOf(".")+1);

    if(exponent === 4)
    return value.split('.').slice(0, -1).join('.')+'-560x380.'+value.substring(value.lastIndexOf(".")+1);

  }

}
