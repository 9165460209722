import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PostService} from '../../../services/post.service';
import {HttpClient, HttpParams} from '@angular/common/http';
@Component({
    selector: 'dc-form-contact',
    templateUrl: './form-contact.component.html',
    styleUrls: ['./form-contact.component.scss'],
})
export class FormContactComponent implements OnInit {
    uploadForm:FormGroup;
    successMsg:String;

    constructor(private http:HttpClient, private formBuilder:FormBuilder, private PostService:PostService) {
        this.successMsg = '';
    }


    ngOnInit() {
        this.uploadForm = this.formBuilder.group({
            name: [''],
            email: [''],
            subject: [''],
            content: [''],
        });
    }

//
//  onSubmit() {
//   console.log(this.uploadForm.value)
//   this.PostService.contact(this.uploadForm.value).subscribe(
//     (response) => {
//     },
//     (error) => {
//     }
//   );
// }
    onSubmit() {
        var formData:any = new FormData();
        formData.append("contact_name", '');
        formData.append("contact_subject", '');
        formData.append("contact_email", '');
        formData.append("contact_phone", '');

        this.PostService.contact(this.uploadForm.value).subscribe(
            (response) => {
                if (!response.error)
                    this.successMsg = response.message;
                console.log(response);
            },
            (error) => {
            }
        );
        
    }

}
