import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from "@angular/router";



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {

            console.log('Error interceptor is working');


            if (error.status === 401) {
                // auto logout if 401 response returned from api
                // location.reload();
            }
            if (error.status === 422) {
                // location.reload();
            }
            if (error.status === 404) {
                console.log('404 error');
                // location.reload();
                // this.router.navigateByUrl('404', { replaceUrl: false });
                this.router.navigate(['/notfound'])
            }

            const err: HttpErrorResponse = error.error.message || error.statusText;
            return throwError(error);
        }));
    }
}
