import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../../services/campaign.service';
import { Campaign } from '../../../models/campaign';
import { GlobalConstants } from '../../../common/global-constants';

@Component({
  selector: 'dc-slider-campaign',
  templateUrl: './slider-campaign.component.html',
  styleUrls: ['./slider-campaign.component.scss'],
})
export class SliderCampaignComponent implements OnInit {
  public currentSlide: number = 0;
  public campaignsList: Campaign[];

  constructor(private campaignService: CampaignService) {}

  ngOnInit() {
    this.campaignService.getCampaigns(3).subscribe(
      (response) => {
        var data = response.data;

        this.campaignsList = response.data;
        console.log(this.campaignsList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  updateSlider(currentSlide: any) {
    alert(currentSlide);
    this.currentSlide = currentSlide;
  }

  public NextSlide(): void {
    if (this.currentSlide == this.campaignsList.length - 1) {
      this.currentSlide = 0;
    } else this.currentSlide++;
  }

  public PrevSlide(): void {
    if (this.currentSlide == 0) {
      this.currentSlide = this.campaignsList.length - 1;
    } else this.currentSlide--;
  }
}
