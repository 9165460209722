<section class="singl-testimonial shadow">
  <div class="container-fluid py-0">
    <div class="row align-items-center gradient gradient-navy-blue text-contrast">
      <div
        class="col-md-7 image-background cover center-top"
        [style.background-image]="'url(' + element[0].image + ')'"
      ></div>

      <div class="col-md-3 mx-auto py-4 py-md-0">
        <blockquote class="bold mt-1 text-contrast">
          <fa-icon [icon]="quoteLeft" [class]="'quote'"></fa-icon>
          {{ element[0].title }}
        </blockquote>

        <hr class="my-4" />
        <div class="small text-contrast">
          <span class="bold d-block">{{ element[0].description }}</span>

        </div>
      </div>
    </div>
  </div>
</section>
