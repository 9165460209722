<link href="https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,700,900" rel="stylesheet" />
<link href="https://fonts.googleapis.com/css?family=Caveat" rel="stylesheet" />
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800&display=swap" rel="stylesheet">
<link href="/assets/css/themify-icons.css" rel="stylesheet" />
<link href="/assets/css/font-awesome.min.css" rel="stylesheet" />
<link href="/assets/css/flaticon.css" rel="stylesheet" />
<link href="/assets/css/odometer-theme-default.css" rel="stylesheet" />
<link href="/assets/css/bootstrap.min.css" rel="stylesheet" />
<link href="/assets/css/animate.css" rel="stylesheet" />
<link href="/assets/css/owl.carousel.css" rel="stylesheet" />
<link href="/assets/css/owl.theme.css" rel="stylesheet" />
<link href="/assets/css/slick.css" rel="stylesheet" />
<link href="/assets/css/slick-theme.css" rel="stylesheet" />
<link href="/assets/css/swiper.min.css" rel="stylesheet" />
<link href="/assets/css/owl.transitions.css" rel="stylesheet" />
<link href="/assets/css/jquery.fancybox.css" rel="stylesheet" />
<link href="/assets/css/style.css" rel="stylesheet" />

<link href="/assets/css/share-style.css" rel="stylesheet" />
<link *ngIf="isArabic" href="/assets/css/ar-style.css" rel="stylesheet" />
<!--&lt;!&ndash; start page-wrapper &ndash;&gt;-->
<div *ngIf="loader" id="loader">
    <div class="boxes">
        <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>

    </div>
</div>

<div  class="page-wrapper">
    <!--&lt;!&ndash; Start header &ndash;&gt;-->
    <header id="header" class="tp-site-header header-style-3">
        <div class="topbar">
            <div class="container">
                <div class="row">

                    <div class="col-sm-6 col-6  ">
                        <p class="address-top-header">
                            <!-- <i class="ti-location-pin"></i>
                            {{ getGlobalThemeOptions().address }} -->
                            <app-language-selector></app-language-selector>
                        </p>
                    </div>
                    <div class="col-sm-6 col-6">
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="{{ getGlobalThemeOptions().facebook }}" target="_blank"><i
                                            class="ti-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="{{ getGlobalThemeOptions().twitter }}" target="_blank"><i
                                            class="ti-twitter-alt"></i></a>
                                </li>
                                <li>
                                    <a href="{{ getGlobalThemeOptions().youtube }}" target="_blank"><i
                                            class="ti-youtube"></i></a>
                                </li>
                                <li>
                                    <a href="{{ getGlobalThemeOptions().linkedin }}" target="_blank"><i
                                            class="ti-linkedin"></i></a>
                                </li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end container -->
        </div>
        <!-- end topbar -->
        <nav class="navigation navbar navbar-default">
            <div class="container">
                <div class="navbar-header">
                    <button type="button" class="open-btn" onclick="$('.tp-site-header #navbar').css('right', 0);">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand" href="/"
                    ><img width="160px" src="{{ getGlobalThemeOptions().logo }}" alt="logo"/><span></span
                    ></a>
                </div>
                <div>
                    <div id="navbar" class="desktop navbar-collapse collapse navigation-holder"
                         [innerHTML]="getGlobalThemeOptions().menu">


                        <!--menu-->
                    </div>
                    <div id="navbar" class="mobile navbar-collapse collapse navigation-holder"
                    >
                        <div onclick=" $('.tp-site-header #navbar').css('right', -300);" class="close-navbar"><i
                                id="close-navbar-btn" class="ti-close"></i></div>
                        <div [innerHTML]="getGlobalThemeOptions().menu"></div>

                        <!--menu-->
                    </div>
                </div>
                <!-- end of nav-collapse -->
                <div class="cart-search-contact">
                    <div class="header-search-form-wrapper">
                        <!--<button class="search-toggle-btn">-->
                        <!--<i class="fi flaticon-magnifying-glass"></i>-->
                        <!--</button>-->
                        <!--<div class="header-search-form">
                          <form role="search" accept-charset="UTF-8" action="" method="GET">
                            <div>
                              <input type="text" class="form-control" id="search" name="q" placeholder="Search here..." />
                              <button type="submit" id="search-submit">
                                <i class="fi flaticon-magnifying-glass"></i>
                              </button>
                            </div>
                          </form>
                        </div>-->
                    </div>
                </div>
            </div>
            <!-- end of container -->
        </nav>
    </header>
</div>
