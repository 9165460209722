export interface PartnerInterface {
  id: string;
  name: string;

  url: string;
  summary: string;
  logo: string;

  created_at: string;
  updated_at: string;
}
export class Partner implements PartnerInterface {
  id: string;
  name: string;
  
  url: string;
  summary: string;
  logo: string;
  created_at: string;
  updated_at: string;
}
