import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { Cta1Component } from './cta1/cta1.component';
import { Cta2Component } from './cta2/cta2.component';

@NgModule({
  declarations: [Cta1Component, Cta2Component],
  imports: [RouterModule, SharedModule],
  exports: [Cta1Component, Cta2Component],
})
export class CtaModule {}
