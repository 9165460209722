<section class="section">
  <div class="container bring-to-front">
    <div class="shadow rounded text-center bg-info-gradient text-contrast p-5">
      <h2 class="mb-5">Want to be an entrepreneur?</h2>
      <p class="handwritten highlight font-md">Buy Dashcore Now!!!</p>

      <a
        href="https://themeforest.net/item/dashcore-saas-startup-software-template/22397137"
        target="_blank"
        class="btn btn-lg btn-warning btn-rounded mt-3"
        >Get it now</a
      >
    </div>
  </div>
</section>
