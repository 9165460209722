<dc-header></dc-header>
<dc-slider-selection></dc-slider-selection>

<section class="section about-us overflow-hidden">
  <div class="container bring-to-front">
    <div class="row gap-y align-items-center">
      <div class="col-md-6 col-lg-5 mr-lg-auto">
        <div class="center-xy op-1">
          <div
            class="shape shape-background rounded-circle shadow-lg bg-info"
            style="width: 600px; height: 600px"
            data-aos="zoom-in"
          ></div>
        </div>

        <div class="device-twin align-items-center">
          <div class="mockup absolute" data-aos="fade-left">
            <div class="screen">
              <img src="assets/img/screens/about-share-foundation--1.jpg" alt="..." />
            </div>
            <span class="button"></span>
          </div>

          <div class="iphone-none front mr-0">
            <div class="screen shadow-box">
              <img src="assets/img/screens/about-share-foundation--2.jpg" alt="..." />
            </div>
            <div class="notch"></div>
          </div>
        </div>
      </div>

      <div class="col-md-6 text-center text-md-left">
        <div class="section-heading">
          <h2 class="bold font-md">
            <img class="favicon-share" width="30px" src="assets/images/sharefoundation-icon.png" />{{ 'About Us' | translate }}
          </h2>
          <p [innerHtml]="getAboutUsText()"></p>
        </div>

        <div class="row gap-y">
          <div class="col-md-6 about-boxes" *ngFor="let box of boxes">
            <div class="media flex-column flex-lg-row align-items-center align-items-md-start">
              <dc-feather [name]="box.icon" class="mx-auto ml-md-0 mr-md-3" [iconClass]="'stroke-primary'"></dc-feather>
              <div class="media-body mt-3 mt-md-0">
                <h5 class="bold mt-0 mb-1">{{ box.title }}</h5>
                <p class="m-0 d-md-none d-lg-block">
                  {{ box.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<dc-testimonial-simple-text-right [option]=1></dc-testimonial-simple-text-right>


<dc-slider-programs [posts]="programmesPosts"></dc-slider-programs>

<!--<dc-home-features></dc-home-features>-->
<dc-slider-campaign></dc-slider-campaign>

<dc-testimonial-simple-text-left></dc-testimonial-simple-text-left>


<!--<dc-corporate-do-business></dc-corporate-do-business>-->

<dc-slider-card-projects></dc-slider-card-projects>
<!--<dc-home-columns-cards></dc-home-columns-cards>-->

<dc-slider-news [posts]="news"></dc-slider-news>

<dc-testimonial-simple-text-right [option]=2></dc-testimonial-simple-text-right>

<dc-slider-bring-to-life></dc-slider-bring-to-life>
<!--<dc-home-purchase></dc-home-purchase>-->
<dc-slider-features-fluid-cols [posts]="microfinancesPosts"></dc-slider-features-fluid-cols>

<dc-online-payment-features></dc-online-payment-features>

<dc-slider-icons [partners]="partners"></dc-slider-icons>

<dc-footer></dc-footer>
