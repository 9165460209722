<!--<section class="section features-carousel b-b">-->
<!--<div class="container">-->
<!--<div class="section-heading text-center">-->
<!--<img width="40px" src="assets/images/sharefoundation-icon.png" />-->
<!--<h2>{{ 'Our Projects' | translate }}</h2>-->
<!--<p class="lead text-secondary">-->
<!--Share Foundation envisions interdependent social and economic society with men, women, girls and boys capable of-->
<!--building and giving in their community.-->
<!--</p>-->
<!--</div>-->
<!--<div class="container pt-0" [class.lift-up]="liftUp">-->
<!--<div class="cards-wrapper">-->
<!--<div class="swiper-container" [swiper]="config">-->
<!--<div class="swiper-wrapper px-1">-->
<!--<div class="swiper-slide px-2 px-sm-1" *ngFor="let project of projects; let i = index">-->
<!--<div class="project card border-0 shadow h-100">-->
<!--<div class="card-body">-->
<!--<div class="logo mx-auto my-3">-->
<!--<img [src]="getImageBySize(project.image, 'featured')" class="img-responsive" alt="" />-->
<!--</div>-->

<!--<hr class="w-50 mx-auto my-3" />-->

<!--<p class="title bold mt-4">-->
<!--{{ project.name }}-->
<!--</p>-->
<!--<p class="regular small text-secondary">-->
<!--{{ project.summary }}-->
<!--</p>-->
<!--</div>-->

<!--<div class="card-footer d-flex align-items-center justify-content-around">-->
<!--<div class="roi">-->
<!--<p class="text-darker lead bold mb-0">1.5k</p>-->
<!--<p class="text-secondary small mt-0">New subscribers</p>-->
<!--</div>-->
<!--<a href="javascript:;">-->
<!--<fa-icon [icon]="infoCircle" size="2x"></fa-icon>-->
<!--</a>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

<!--&lt;!&ndash; Add Arrows &ndash;&gt;-->
<!--<div class="text-primary features-nav features-nav-next">-->
<!--<span class="text-uppercase small">{{ 'Next' | translate }}</span>-->
<!--<fa-icon [icon]="longArrowAltRight" [class]="'features-nav-icon'"></fa-icon>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</section>-->


<section class="section projects">
    <div class="container bring-to-front">
        <div class="section-heading text-center">
            <img width="40px" src="assets/images/sharefoundation-icon.png"/>
            <h2 class="bold" >{{ 'Our Projects' | translate }}</h2>
            <p class="lead text-secondary">
            </p>
        </div>

        <div class="row gap-y">
            <div *ngIf="getProjectMain()!=null" class="col-lg-3 promo-column">
                <div class="rounded promo-block zoom-background">
                    <div
                            class="absolute top right bottom left image-background cover overlay overlay-dark alpha-1 w-100 h-100"
                            [style.background-image]="'url(' +getImageBySize(projects[0].image, 'featured') + ')'"
                    ></div>

                    <div class="content position-relative p-4">
                        <h4 class="text- mt-0">{{ projects[0].name }}</h4>
                        <p class="text- mt-0">{{ projects[0].summary }}.</p>
                        <a href="page/project/{{ projects[0].slug}}" class="btn btn-contrast"> {{ 'Learn More' | translate }}</a>
                    </div>
                </div>
                <div class="rounded promo-block zoom-background mt-4">
                    <div
                            class="absolute top right bottom left image-background cover overlay overlay-dark alpha-1 w-100 h-100"
                            [style.background-image]="'url(' +getImageBySize(projects[1].image, 'featured') + ')'"
                    ></div>

                    <div class="content position-relative p-4">
                        <h4 class="text- mt-0">{{ projects[1].name }}</h4>
                        <p class="text- mt-0">{{ projects[1].summary }}.</p>
                        <a href="page/project/{{ projects[1].slug}}" class="btn btn-contrast"> {{ 'Learn More' | translate }}</a>
                    </div>
                </div>
            </div>
            <div *ngIf="getProjectMain()!=null" class="col-lg-6 promo-column main">
                <div class="rounded promo-block zoom-background">
                    <div
                            class="absolute top right bottom left image-background cover overlay overlay-dark alpha-1 w-100 h-100"
                            [style.background-image]="'url(' +getImageBySize(getProjectMain().image, 'featured') + ')'"
                    ></div>

                    <div class="content position-relative p-4">
                        <h4 class="text- mt-0">{{ getProjectMain().name }}</h4>
                        <p class="text- mt-0">{{ getProjectMain().summary }}.</p>
                        <a href="page/project/{{ getProjectMain().slug}}" class="btn btn-contrast"> {{ 'Learn More' | translate }}</a>
                    </div>
                </div>

            </div>
            <div *ngIf="getProjectForth()!=null" class="col-lg-3 promo-column">
                <div class="rounded promo-block zoom-background">
                    <div
                            class="absolute top right bottom left image-background cover overlay overlay-dark alpha-1 w-100 h-100"
                            [style.background-image]="'url(' +getImageBySize(getProjectForth().image, 'featured') + ')'"
                    ></div>

                    <div class="content position-relative p-4">
                        <h4 class="text- mt-0">{{ getProjectForth().name }}</h4>
                        <p class="text- mt-0">{{ getProjectForth().summary }}.</p>
                        <a href="page/project/{{ getProjectForth().slug}}" class="btn btn-contrast"> {{ 'Learn More' | translate }}</a>
                    </div>
                </div>
                <div *ngIf="getProjectFifth()!=null" class="rounded promo-block zoom-background mt-4">
                    <div
                            class="absolute top right bottom left image-background cover overlay overlay-dark alpha-1 w-100 h-100"
                            [style.background-image]="'url(' +getImageBySize(getProjectFifth().image, 'featured') + ')'"
                    ></div>

                    <div class="content position-relative p-4">
                        <h4 class="text- mt-0">{{ getProjectFifth().name }}</h4>
                        <p class="text- mt-0">{{ getProjectFifth().summary }}.</p>
                        <a href="page/project/{{ getProjectFifth().slug}}" class="btn btn-contrast"> {{ 'Learn More' | translate }}</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

