import { Component, OnInit, Input } from '@angular/core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { HelperService } from '../../../helpers/helper.service';

import { Post } from '../../../models/post';
@Component({
  selector: 'dc-slider-features-fluid-cols',
  templateUrl: './slider-features-fluid-cols.component.html',
  styleUrls: ['./slider-features-fluid-cols.component.scss'],
})
export class SliderFeaturesFluidColsComponent implements OnInit {
  longArrowAltRight = faLongArrowAltRight;
  @Input()
  posts: Post[];

  slidingConfig: SwiperConfigInterface = {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    navigation: true,
    pagination: {
      el: '.tools-pagination',
      clickable: true,
      dynamicBullets: false,
    },
  };

  fadingConfig: SwiperConfigInterface = {
    effect: 'fade',
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    navigation: true,
  };

  currentSlide: number = 0;

  constructor(private helperService: HelperService) {}

  ngOnInit() {}

  updateSlider(currentSlide: any) {
    this.currentSlide = currentSlide;
  }

  public onIndexChange(index: number): void {
    this.currentSlide = index;
  }
  getImageBySize(imagUrlm: any, size: any) {
    return this.helperService.getImage(imagUrlm, size);
  }
}
