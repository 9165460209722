import { Component, OnInit } from '@angular/core';
import { Cpu } from 'angular-feather/icons';

@Component({
  selector: 'dc-about-video',
  templateUrl: './about-video.component.html',
  styleUrls: ['./about-video.component.scss'],
})
export class AboutVideoComponent implements OnInit {
  cpu = Cpu;
  constructor() {}

  ngOnInit() {}
}
