import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FeatherModule } from 'angular-feather';

import { allIcons } from 'angular-feather/icons';

import { LoaderComponent } from './loader/loader.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { FeatherComponent } from './feather/feather.component';
import { BadgeComponent } from './badge/badge.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { HomeFooterComponent } from './home-footer/home-footer.component';
import { I18nModule } from '../i18n/i18n.module';
import { MediaPipe } from './media.pipe';

// Select some icons (use an object, not an array)
const icons = allIcons;

@NgModule({
  imports: [CommonModule, HttpClientModule, FeatherModule.pick(icons), FontAwesomeModule, TranslateModule, I18nModule],
  declarations: [
    LoaderComponent,
    PageHeaderComponent,
    FeatherComponent,
    BadgeComponent,
    HeaderComponent,
    HomeFooterComponent,
    MediaPipe
  ],
  exports: [
    LoaderComponent,
    PageHeaderComponent,
    FeatherComponent,
    BadgeComponent,

    CommonModule,
    HttpClientModule,
    FontAwesomeModule,
    TranslateModule,
    I18nModule,
    HeaderComponent,
    HomeFooterComponent,
    MediaPipe
  ],
})
export class SharedModule {}
