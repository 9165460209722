<dc-online-payment-heading></dc-online-payment-heading>

<dc-online-payment-features></dc-online-payment-features>
<dc-online-payment-steps></dc-online-payment-steps>

<dc-divider-curve shapeCssText="darker"></dc-divider-curve>
<dc-online-payment-customers-management></dc-online-payment-customers-management>
<dc-divider-curve shapeCssText="contrast"></dc-divider-curve>

<dc-online-payment-register></dc-online-payment-register>
<dc-testimonial-world-map></dc-testimonial-world-map>
<dc-trust-us [title]="'They trust us'"></dc-trust-us>
<dc-online-payment-send-receive></dc-online-payment-send-receive>
<dc-cta1 [edge]="'top-left'" [background]="'contrast'"></dc-cta1>

<dc-footer3></dc-footer3>
