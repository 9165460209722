export interface SliderInterface {
  id: string;
  name: string;
  main_slide: string;
  button_1: string;
  button_1_url: string;
  created_at: string;
  updated_at: string;
}
export class Slider implements SliderInterface {
  id: string;
  name: string;
  main_slide: string;
  button_1: string;
  button_1_url: string;
  created_at: string;
  updated_at: string;
}
