import { Component, OnInit, Input } from '@angular/core';

import { Post } from '../../../models/post';
@Component({
  selector: 'dc-slider-news',
  templateUrl: './slider-news.component.html',
  styleUrls: ['./slider-news.component.scss'],
})
export class SliderNewsComponent implements OnInit {
  @Input()
  posts: Post[];

  constructor() {}

  ngOnInit() {}
}
