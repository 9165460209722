import {Component, OnInit, Input} from '@angular/core';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';

import {ProjectService} from '../../../services/project.service';
import {Project} from '../../../models/project';
import {GlobalConstants} from '../../../common/global-constants';
import {HelperService} from '../../../helpers/helper.service';

import {faInfoCircle, faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'dc-slider-card-projects',
    templateUrl: './slider-card-projects.component.html',
    styleUrls: ['./slider-card-projects.component.scss'],
})
export class SliderCardProjectsComponent implements OnInit {
    infoCircle = faInfoCircle;
    longArrowAltRight = faLongArrowAltRight;

    @Input()
    liftUp:boolean = true;
    public projects:Project[];
    features = [
        {
            name: 'Integration',
            summary: 'Consequuntur ea sapiente ut',
            image: '',
        },
        {
            name: 'Strategy',
            summary: 'Alias eum expedita illo rem!',
            image: '',
        },
        {
            name: 'Money',
            summary: 'Consectetur adipisicing elit',
            image: '',
        },
        {
            name: 'Brain',
            summary: 'Rem repellendus rerum, vel!',
            image: '',
        },
        {
            name: 'Support',
            summary: 'Consectetur adipisicing elit',
            image: '',
        },
    ];

    config:SwiperConfigInterface = {
        slidesPerView: 1,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
        loop: true,
        breakpoints: {
            '1366': {slidesPerView: 3, spaceBetween: 40},
            '1024': {slidesPerView: 3, spaceBetween: 40},
            '992': {slidesPerView: 3, spaceBetween: 15},
            '768': {slidesPerView: 2.5, spaceBetween: 15},
            '576': {slidesPerView: 1.5, spaceBetween: 10},
        },
        navigation: {
            nextEl: '.features-nav-next',
            prevEl: '.features-nav-prev',
        },
        pagination: false,
        observer: true,
        watchOverflow: true,
        mousewheel: true,
    };

    constructor(private projectService:ProjectService, private helperService:HelperService) {
    }

    ngOnInit() {
        // this.projects =this.features;
        this.projectService.getProjects(5).subscribe(
            (response) => {
                var data = response.data;

                this.projects = response.data;
                console.log(this.projects);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    getImageBySize(imagUrlm:any, size:any) {
        return this.helperService.getImage(imagUrlm, size);
    }

    getProjectMain() {

        if(this.projects){
            if (this.projects.length > 2) {
                return this.projects[2];
            } else
                return null;
        }
        
    }
    getProjectForth() {
        if(this.projects){
        if (this.projects.length > 3) {
            return this.projects[3];
        } else
            return null;
    }
    }
    getProjectFifth() {

        if(this.projects){
        if (this.projects.length > 4) {
            return this.projects[4];
        } else
            return null;
    }
}
}
