<section class="section partners {{ background ? 'bg-' + background : '' }} {{ edge ? 'edge ' + edge : '' }}">
  <div class="container py-5" [class.border-bottom]="bordered">
    <h4 class="bold text-center mb-5 text-secondary" *ngIf="title">{{ title }}</h4>
    <swiper [config]="config" class="swiper-container pb-5" *ngIf="partners">
        <div class="swiper-slide" style="display: flex;display: flex;align-items: center;justify-content: center;" *ngFor="let partner of partners">
        <a href="{{partner.url}}" target="_blank">
            <img src="{{partner.logo}}" class="img-responsive" alt="{{partner.name}}" style="max-height: 130px" />
        </a>
        </div>
    </swiper>
  </div>
</section>
