import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/@shared';

import { FormRegisterSimpleInlineComponent } from './form-register-simple-inline/form-register-simple-inline.component';
import { FormContactComponent } from './form-contact/form-contact.component';
import { FormRegisterCompanyComponent } from './form-register-company/form-register-company.component';

@NgModule({
  declarations: [FormRegisterSimpleInlineComponent, FormContactComponent, FormRegisterCompanyComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  exports: [FormRegisterSimpleInlineComponent, FormContactComponent, FormRegisterCompanyComponent],
})
export class ActionsModule {}
