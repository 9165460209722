<section class="singl-testimonial shadow">
    <div class="container-fluid py-0">
        <div class="row align-items-center gradient gradient-primary-dark text-contrast">
            <div class=".col-lg-3 col-md-4 mx-auto py-4 py-md-0">


                <blockquote class="bold mt-1 text-contrast">
                    <fa-icon [icon]="quoteLeft" [class]="'quote'"></fa-icon>
                    {{ 'quote2' | translate }}
                  </blockquote>


                <p class=" bold mt-1 text-contrast">{{ 'quote2 subtitle' | translate}}</p>
                <hr class="my-4"/>
                <div class="small text-contrast">

                    {{ 'quote2 content' | translate }}
                </div>
            </div>

            <div
                    class="col-12 col-md-7 image-background cover"
                    [style.background-image]="'url(https://backend.sharefoundation.co.uk//storage/2019531233149784636949423097844518.jpg)'"
            ></div>
        </div>
    </div>
</section>
