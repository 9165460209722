import { Component, OnInit, Input} from '@angular/core';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'dc-testimonial-simple-text-right',
  templateUrl: './testimonial-simple-text-right.component.html',
  styleUrls: ['./testimonial-simple-text-right.component.scss'],
})
export class TestimonialSimpleTextRightComponent implements OnInit {
  quoteLeft = faQuoteLeft;
  @Input()
  option: number;
  
  constructor() {}

  element = [
    {
        image: 'Integration',
        title: 'Consequuntur ea sapiente ut',
        description: '',
        subtitle:''
    },
];

  ngOnInit() {

    if(this.option === 1){
      this.element =  [
        {
            image: 'https://backend.sharefoundation.co.uk//storage/projects/sharefoundation-img.jpg',
            title: 'مشروع الخبز المدعوم',
            description: 'منذ بداية الشهر الثامن عام 2017 وحتى الآن استمر مشروع الخبز المدعوم بتوفير مادة الطحين للمخابز العاملة في المناطق المستهدفة في ادلب وريف حلب لإنتاج الخبز وتوفيره في السوق المحلية بسعر التكلفة',
            subtitle: '',
        }];
    }
    if(this.option === 2){
      this.element =  [
        {
            image: 'https://backend.sharefoundation.co.uk//storage/724be95a-40a8-46e5-81bc-9b553ce25b4f-min.jpeg',
            title: 'مستمرون بالعطاء..',
            description: 'بعد عام على تأسيس صندوق نماء كمشروع اقتصادي منبثق عن مؤسسة شارك ما زال الصندوق يعمل على تقديم القروض لرواد الاعمال واصحاب المشاريع الصغيرة',
            subtitle: ' في بناء قدرات الأفراد وخاصة النساء للخروج من آثار الأزمات التي تزيد من حدة الفقر',
        }];
    }

    if (localStorage.getItem('language') !== 'ar'){
      if(this.option === 1){
        this.element =  [
          {
              image: 'https://backend.sharefoundation.co.uk//storage/projects/sharefoundation-img.jpg',
              title: 'Subsidized Bread Project',
              description: 'Since the beginning of the eighth month in 2017 until now, the subsidized bread project has continued to provide flour for bakeries operating in the target areas of Idlib and rural Aleppo to produce bread and provide it in the local market at a cost price',
              subtitle: '',
          }];
      }
      if(this.option === 2){
        this.element =  [
          {
              image: 'https://backend.sharefoundation.co.uk//storage/724be95a-40a8-46e5-81bc-9b553ce25b4f-min.jpeg',
              title: 'Success Story',
              description: 'The success story of Ms. Ismahan, one of the beneficiaries of Nama microfinance fund loans in Sharq Beit Al Muna project',
              subtitle: 'Building the capacity of individuals, especially women, to emerge from crises that exacerbate poverty',
          }];
      }
    }



  }
}
