<section class="section programs b-b b-t bg-light">
  <div class="container ">
    <div class="section-heading text-center">
      <img width="40px" src="assets/images/sharefoundation-icon.png" />
      <h2 class="bold">{{ 'Programs' | translate }}</h2>
      <p class="lead text-secondary">
       {{ 'Promoting social' | translate}}
      </p>
    </div>

    <div class="row">
      <div class="col-md-4 col-lg-4 ml-lg-auto order-md-2">
        <nav
          id="sw-nav-developers"
          class="nav flex-md-column justify-content-between justify-content-md-start nav-pills nav-pills-light nav-fill"
        >
          <a
            class="nav-item nav-link bold text-md-left"
            [class.active]="i === currentSlide"
            (click)="updateSlider(i)"
            href="javascript:void(0)"
            *ngFor="let item of posts; let i = index"
          >
            {{ item.name }}</a
          >
        </nav>

        <hr class="mt-5" />
        <a href="all/programmes" class="nav-link text-primary"
          >{{ 'All Programs' | translate }}<fa-icon [icon]="longArrowAltRight" class="mr-2"></fa-icon
        ></a>
      </div>

      <div class="col-md-8">
        <div class="mt-4 mt-md-0 line-numbers" [attr.index]="currentSlide" (indexChange)="onIndexChange($event)">
          <div class="swiper-slide" *ngFor="let item of posts; let j = index">
            <div *ngIf="currentSlide === j">
              <div class="p-card col-md-12">
                <div class="col-md-6 p-img">
                  <img class="" src="{{ item.image }}" />
                </div>
                <div class="col-md-6 p-content">
                  <h3>{{ item.name }}</h3>
                  <div [innerHtml]="item.description"></div>
                  <!-- <ngx-prism [attr.language]="item.name">
                    {{ item.description }}
                  </ngx-prism> -->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
