/**
 * Created by Abdulhamid on 7/16/2021.
 */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor() {}

  public getImage(url: any, size: any) {
    var filename = url.split('/').pop();
    var fileArray = filename.split('.');
    if (size == 'thumb') return url.replace(filename, fileArray[0] + '-150x150' + '.' + fileArray[1]);
    else if (size == 'featured') return url.replace(filename, fileArray[0] + '-560x380' + '.' + fileArray[1]);
    else if (size == 'medium') return url.replace(filename, fileArray[0] + '-540x360' + '.' + fileArray[1]);
    return url;
  }
}
