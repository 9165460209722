<section class="section bg-light">
  <div class="container bring-to-front">
    <div class="row gap-y align-items-center">
      <div class="col-md-6 ml-md-auto text-center text-md-left">

        <h2 class="mt-3">{{ 'Our Projects Ready To Market' | translate }}</h2>
        <p class="text-secondary">
          <!--{{ 'Our Projects Details' | translate }}-->
        </p>

        <hr />
        <swiper [index]="currentSlide" (indexChange)="onIndexChange($event)">
          <div class="swiper-slide" *ngFor="let project of projects">
            <h3>{{ project.name }}</h3>
            <p class="small text-center text-primary">{{ project.summary }}</p>

            <figure class="shadow-box">
              <a href="page/project/{{ project.slug}}" class="btn btn-contrast">
                <img [src]="project.image" alt="" class="img-responsive rounded" /></a>
            </figure>

          </div>
        </swiper>
      </div>
      
      <div class="col-md-6">
        <div class="rounded bg-primary-gradient shadow">
          <div class="">
            <img src="https://backend.sharefoundation.co.uk//storage/projects/charity-hands-family-generic-885-1.jpg" class="img-responsive w-50" alt="" />
          </div>
        </div>

        <div class="rounded shadow-box bg-contrast mt-3">
          <div class="media align-items-center px-3">

            <div class="media-body my-3 pl-3 b-l">
              <a href="page/project/{{ projects[1].slug}}"><p class="bold text-primary my-0">{{ projects[1].name }}</p></a>
              <p class="my-0 text-secondary">{{ projects[1].summary }}</p>
            </div>
          </div>
        </div>

        <div class="rounded shadow-box bg-contrast mt-3">
          <div class="media align-items-center px-3">

            <div class="media-body my-3 pl-3 b-l">
              <a href="page/project/{{ projects[2].slug}}"><p class="bold text-primary my-0">{{ projects[2].name }}</p></a>
              <p class="my-0 text-secondary">{{ projects[2].summary }}</p>

              <hr class="my-3" />
              <nav id="sw-nav-1" class="nav nav-tabs tabs-clean border-bottom-0">
                <a
                  class="nav-item nav-link py-0 d-flex flex-column align-items-center border-bottom-0"
                  href="javascript:void(0)"
                  [class.active]="i === currentSlide"
                  [class.pl-md-0]="i === 0"
                  [attr.data-step]="i + 1"
                  (click)="updateSlider(i)"
                  *ngFor="let project of projects; let i = index"
                >
                  {{i+1}}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
