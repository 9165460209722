<section class="section">
  <div class="container pt-0 bring-to-front mt-n9">
    <div class="row">
      <div class="col-md-8 mx-md-auto">
        <div class="card bg-dark shadow border-0">
          <div class="card-body py-4 px-5">
            <div class="mb-4 text-center">
              <dc-feather name="mail" size="36"></dc-feather>
              <p class="bold lead text-contrast mb-0">Be the first to get all our updates</p>
              <div class="text-muted">We'll never share your email address</div>
            </div>

            <p class="handwritten highlight font-md mb-4">Why wait?</p>

            <dc-form-register-simple-inline></dc-form-register-simple-inline>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
