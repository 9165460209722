export interface themeOptionsInterface {
  site_title: string;
  who_we_are: string;
  whatsapp: string;
  footer_menu: string;
  menu: string;
  logo: string;
  facebook: string;
  youtube: string;
  twitter: string;
  linkedin: string;
  phone: string;
  email: string;
  address: string;

  about_us_text: string;
  address_details: string;
}

export class ThemeOptions implements themeOptionsInterface {
  site_title: string;
  who_we_are: string;
  whatsapp: string;
  footer_menu: string;
  menu: string;
  logo: string;
  facebook: string;
  youtube: string;
  twitter: string;
  linkedin: string;
  phone: string;
  email: string;
  address: string;

  about_us_text: string;
  address_details: string;

  // public readFromJson(jsonResponse) {
  //   let object = jsonResponse;
  //   this.title = object.name;
  //   this.id = object.id;
  //   this.content = object.content;
  // }
}
