<!-- ./Footer - Headline -->
<footer class="site-footer section bg-darker text-contrast text-center">
  <div class="container {{ containerClass }}">
    <img src="assets/img/logo-light.png" alt="" class="logo" />

    <p class="lead mt-5">Don't wait - <span class="bold">Get DashCore</span> NOW!</p>

    <p class="copyright my-5">
      © 2021 Dashcore, <em>by</em>
      <a class="brand bold" href="https://www.5studios.net" target="_blank">5Studios.net</a>
    </p>

    <hr class="mt-5 bg-secondary op-5" />
    <nav class="nav social-icons justify-content-center mt-4">
      <a href="#" class="btn text-contrast btn-circle btn-sm brand-facebook mr-3"
        ><fa-icon [icon]="icons.facebook"></fa-icon
      ></a>
      <a href="#" class="btn text-contrast btn-circle btn-sm brand-twitter mr-3"
        ><fa-icon [icon]="icons.twitter"></fa-icon
      ></a>
      <a href="#" class="btn text-contrast btn-circle btn-sm brand-youtube mr-3"
        ><fa-icon [icon]="icons.youtube"></fa-icon
      ></a>
      <a href="#" class="btn text-contrast btn-circle btn-sm brand-pinterest"
        ><fa-icon [icon]="icons.pinterest"></fa-icon
      ></a>
    </nav>
  </div>
</footer>
