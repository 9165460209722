<section id="features" class="section payment-features bg-contrast edge top-left">
  <div class="container">
    <div class="section-heading mb-6 text-center">
      <h2 class="bold">{{ 'Media Library' | translate }}</h2>
    </div>

    <div class="row gap-y text-center text-md-left">
      <div class="col-md-4 py-4 position-relative text-center" *ngFor="let element of elements">
        <div class="card lift-hover shadow-hover">
          <a href="{{ element.url }}" target="_blank">
          <div class="card-body p-4">
            <div class="icon-shape mb-4">
              <div class="shape shape-circle"></div>
              <dc-feather [name]="element.icon" [iconClass]="'stroke-contrast'" [class]="'icon center-xy'"></dc-feather>
            </div>
            <h5 class="bold text-capitalize">{{ element.title | translate}}</h5>
          </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
