<dc-corporate-heading></dc-corporate-heading>

<dc-slider-icons></dc-slider-icons>
<dc-features-icons-col></dc-features-icons-col>
<dc-slider-start-right-way></dc-slider-start-right-way>
<dc-testimonial-simple-text-left></dc-testimonial-simple-text-left>
<dc-corporate-do-business></dc-corporate-do-business>
<dc-big-screen [img]="'assets/img/screens/dash/2.png'" [screenPos]="'left'">
  <h2>Lightweight HTML template great for your product</h2>
  <p class="lead text-secondary">
    Suspendisse dignissim lorem vel elit dapibus mattis. Etiam magna nunc, maximus bibendum diam et, porttitor.
  </p>
  <p>
    Vivamus ut mauris vel orci ornare hendrerit. Pellentesque eu enim ipsum. In mauris tellus, gravida nec euismod et,
    egestas et tortor. Sed ante ipsum, scelerisque vel enim eu, facilisis consectetur elit.
  </p>
  <a href="#" class="btn btn-info btn-rounded mt-4">Learn More</a>
</dc-big-screen>
<dc-big-screen [img]="'assets/img/screens/dash/1.png'" [screenPos]="'right'">
  <h2>Designed to provide top-notch appearance</h2>
  <p class="lead text-secondary">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
    aliqua.
  </p>
  <p>
    Nullam vitae scelerisque est, sed tempus metus. Donec convallis volutpat enim consequat viverra. Nam blandit est eu
    ipsum elementum, ac pellentesque nibh placerat. Quisque venenatis pulvinar nulla, non vestibulum urna ultrices
    accumsan.
  </p>
  <a href="#" class="btn btn-info btn-rounded mt-4">Learn More</a>
</dc-big-screen>
<dc-testimonial-simple-text-right></dc-testimonial-simple-text-right>

<dc-faqs1></dc-faqs1>
<dc-cta2 [containerClass]="'pt-0'"></dc-cta2>
<dc-cta1 cssClass="bg-light" edge="top-left"></dc-cta1>
<dc-footer3 containerClass="py-4"></dc-footer3>
