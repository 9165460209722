<section class="section">
  <div class="container">
    <div class="section-heading text-center">
      <h2 class="bold">Everything you need - all in one theme</h2>
    </div>

    <nav class="slide nav nav-tabs nav-outlined nav-rounded justify-content-around justify-content-md-center mb-5">
      <a
        class="nav-item nav-link"
        [class.active]="i === currentSlide"
        href="javascript:;"
        (click)="updateSlider(i)"
        *ngFor="let item of ['Dashboard', 'Invoicing', 'Calendar', 'Inbox']; let i = index"
      >
        {{ item }}
      </a>
    </nav>

    <swiper [index]="currentSlide" (indexChange)="onIndexChange($event)">
      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-6 mr-md-auto">
              <div class="browser shadow">
                <img src="assets/img/screens/dash/4.png" class="img-responsive" alt="" />
              </div>
            </div>

            <div class="col-md-5">
              <dc-badge [bg]="'contrast'" [text]="'primary'">Included</dc-badge>
              <h2 class="display-4 light">Dashboard</h2>
              <p>
                When buying Dashcore you will get right in your hand a powerful tool to boost your design. You will get
                not just a template but a complete ready-to-use HTML code snippets to start right away with your
                project.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-6 order-md-2 ml-md-auto">
              <div class="browser shadow">
                <img src="assets/img/screens/dash/3.png" alt="" class="img-responsive" />
              </div>
            </div>

            <div class="col-md-5 mr-md-auto">
              <dc-badge [bg]="'contrast'" [text]="'primary'">New feature</dc-badge>
              <h2 class="display-4 light">Invoicing</h2>
              <p class="lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore maxime numquam perspiciatis saepe
                totam.
              </p>

              <hr class="mt-5" />
              <p class="small text-secondary">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium aspernatur atque dolor dolorem ea
                esse expedita hic iste laboriosam libero nihil nostrum obcaecati, odio qui reprehenderit sint vel veniam
                voluptatibus.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-6 mr-md-auto">
              <div class="browser shadow-box">
                <img src="assets/img/screens/dash/2.png" alt="" class="img-responsive" />
              </div>

              <img
                src="assets/img/screens/dash/2.1.png"
                alt=""
                class="absolute img-responsive shadow rounded"
                style="right: 0; top: 0"
              />
            </div>

            <div class="col-md-5">
              <dc-badge [bg]="'contrast'" [text]="'primary'">Always on Time</dc-badge>
              <h2 class="display-4 light">Calendar</h2>
              <p class="lead text-secondary">Calendar plugin included and beautified</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias atque cum, delectus dicta doloribus enim
                fuga hic itaque modi nobis pariatur porro quasi ratione repellat sint veniam veritatis voluptas
                voluptates.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="container-fluid pt-0 pb-4">
          <div class="row gap-y align-items-center">
            <div class="col-md-6 order-md-2 ml-md-auto">
              <div class="browser shadow">
                <img src="assets/img/screens/dash/1.png" alt="" class="img-responsive" />
              </div>
            </div>

            <div class="col-md-5">
              <dc-badge [bg]="'contrast'" [text]="'primary'">Outstanding</dc-badge>
              <h2 class="display-4 light">Inbox</h2>
              <p class="lead">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab, accusantium aliquid dolore dolorem
                excepturi exercitationem fugiat incidunt iure nesciunt nihil non numquam perferendis quaerat quisquam
                rem saepe sunt temporibus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </swiper>
  </div>
</section>
