import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { ThemeOptions } from '../models/themeOptions';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ThemeOptionsService {
  private url = 'https://backend.sharefoundation.co.uk/api/v1/get-theme-options';

  public themeOptions: ThemeOptions = new ThemeOptions();

  constructor(private http: HttpClient) {}

  getThemeOptions(): Observable<any> {
    return this.http.get<any>(this.url);
    //return this.themeOptions;
  }
  sendContact(): Observable<any> {
    return this.http.get<any>('https://backend.sharefoundation.co.uk/api/v1/contact/send');
    //return this.themeOptions;
  }
  // getOption(key){
  //     return this.themeOptions[key];
  // }
}
