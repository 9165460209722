import { Injectable } from '@angular/core';
import { Partner } from '../models/partner';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../common/global-constants';
@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  private url = GlobalConstants.apiURL + 'get-partners';

  partner: Partner = new Partner();

  constructor(private http: HttpClient) {}

  getPartners(limit: any): Observable<any> {
    const params = new HttpParams({
      fromObject: { limit: limit },
    });
    var partner = this.http.get<any>(this.url, { params });
    console.log(partner);
    return partner;
  }
}
