<section class="section bg-light bg-light-gradient edge bottom-right">
    <div class="container bring-to-front">
        <div class="section-heading text-center">
            <img width="40px" src="assets/images/sharefoundation-icon.png" />
            <h2 class="bold">{{'Namaa'|translate}}</h2>
        </div>

        <div class="row gap-y">
            <div class="col-md-6" *ngFor="let post of posts">
                <div
                        class="rounded bg-contrast shadow-box image-background off-left-background lift-hover p-4 pl-6 pl-md-9"
                        [style.background-image]="'url(' + getImageBySize(post.image, 'featured') + ')'"
                >
                    <h3>{{ post.name }}</h3>
                    <p class="text-secondary mb-0">
                        {{post.description}}
                    </p>
                    <a href="post/{{ post.slugable.key }}" class="read-more">{{ 'Read More...' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</section>
