import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-home-columns-cards',
  templateUrl: './home-columns-cards.component.html',
  styleUrls: ['./home-columns-cards.component.scss'],
})
export class HomeColumnsCardsComponent implements OnInit {
  promos = [
    {
      col: 3,
      items: [
        {
          title: 'Wearable',
          description: 'Smart electronic devices for your day to day life',
          img: 'wearable',
          overlay: { color: 'dark', alpha: 1 },
          text: { title: 'contrast', description: 'light' },
        },
        {
          title: 'Innovative',
          description: 'Make your life easeier with these smart solutions',
          img: 'innovative',
          overlay: { color: 'dark', alpha: 1 },
          text: { title: 'contrast', description: 'light' },
        },
      ],
    },
    {
      col: 6,
      items: [
        {
          title: 'Smartphones',
          description: 'Your personal smartphone are just one click away',
          img: 'smartphone',
          overlay: { color: 'light', alpha: 3 },
          text: { title: 'darker', description: 'dark' },
        },
      ],
    },
    {
      col: 3,
      items: [
        {
          title: 'Wifi',
          description: 'Exclusive high quality wifi technology to extend your network',
          img: 'wifi',
          overlay: { color: 'light', alpha: 3 },
          text: { title: 'darker', description: 'dark' },
        },
        {
          title: 'Headphones',
          description: 'Enjoy your favorite playlist with an amazing sound quality',
          img: 'headphones-yellow-bg',
          overlay: { color: 'dark', alpha: 1 },
          text: { title: 'contrast', description: 'light' },
        },
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
