/**
 * Created by Abdulhamid on 7/8/2021.
 */
import { ThemeOptions } from '../models/themeOptions';

export class GlobalConstants {
  public static apiURL: string = 'https://backend.sharefoundation.co.uk/api/v1/';
  public static siteTitle: string = 'test';
  public static lang: string = localStorage.getItem('language');
  public static globalThemeOptions: ThemeOptions = new ThemeOptions();
}
