import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-testimonial-simple-slider-boxed',
  templateUrl: './testimonial-simple-slider-boxed.component.html',
  styleUrls: ['./testimonial-simple-slider-boxed.component.scss'],
})
export class TestimonialSimpleSliderBoxedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
