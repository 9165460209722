<section class="hero-style-1">
  <div class="swiper-container">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let slide of slides" class="slide swiper-slide">
        <div class="slide-inner slide-bg-image" style="background: url({{ slide.main_slide }}); background-size: cover ">
          <div class="container">
            <div data-swiper-parallax="200" class="slide-thumb">
              <span>{{ 'GO FOR HELP' | translate }}</span>
            </div>
            <div data-swiper-parallax="300" class="slide-title">
              <h2>{{ slide.name }}</h2>
            </div>
            
            <div class="clearfix"></div>
            <div data-swiper-parallax="500" class="slide-btns">
              <a href="{{ slide.button_1_url }}" class="theme-btn"
                >{{ slide.button_1 }}<i class="fa fa-angle-double-right" aria-hidden="true"></i
              ></a>
            </div>
          </div>
          <div class="slide-shape">
            <img src="assets/images/shape/shape.png" alt="{{ slide.name }}" />
          </div>
        </div>
        <!-- end slide-inner -->
      </div>
    </ngx-slick-carousel>
    <div class="swiper-pagination"></div>
  </div>
</section>
