<section class="section bg-darker">
  <div class="container pb-12">
    <div class="section-heading text-center">
      <h2 class="bold text-contrast">A complete <span class="bold">Customer Management</span> to power your sales</h2>
      <p class="lead text-secondary">
        Culpa cupiditate deleniti dignissimos ducimus fugit id impedit ipsa itaque, laborum libero maiores nam non nulla
        quasi quia quisquam temporibus ullam voluptate.
      </p>
    </div>

    <div class="centered-screen">
      <div class="absolute-md right message text-center text-md-left" data-aos="fade-up" data-aos-delay="500">
        <span class="bg-primary p-3 m-0 text-contrast arrow bottom small rounded">Customers administration.</span>
      </div>

      <div class="px-5 mt-5 mt-md-0">
        <img
          src="assets/img/screens/payments/customer-list.png"
          alt=""
          class="shadow-lg rounded img-responsive mx-auto op-7"
        />
      </div>

      <img
        src="assets/img/screens/payments/highlight.png"
        alt=""
        class="absolute shadow-lg rounded img-responsive screen-highlight"
        data-aos="zoom-in"
      />
    </div>
  </div>
</section>
