export interface CampaignInterface {
  id: string;
  name: string;
  slug: string;
  summary: string;
  content: string;
  image: string;
  donation_total: string;
  donation_goal: string;
  created_at: string;
  updated_at: string;
}
export class Campaign implements CampaignInterface {
  id: string;
  name: string;
  slug: string;
  summary: string;
  content: string;
  image: string;
  donation_total: string;
  donation_goal: string;

  seo_title: string;
  seo_description: string;

  created_at: string;
  updated_at: string;
}
