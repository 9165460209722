import { Component, OnInit } from '@angular/core';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { PostService } from '../../../../services/post.service';
import { SliderService } from '../../../../services/slider.service';
import { Slider } from '../../../../models/slider';
import { GlobalConstants } from '../../../../common/global-constants';
@Component({
  selector: 'dc-slider-selection',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss'],
})
export class HomeSliderComponent implements OnInit {
  public slides: Slider[];

  slides2 = [
    { img: 'https://via.placeholder.com/600.png/09f/fff' },
    { img: 'https://via.placeholder.com/600.png/021/fff' },
    { img: 'https://via.placeholder.com/600.png/321/fff' },
    { img: 'https://via.placeholder.com/600.png/422/fff' },
    { img: 'https://via.placeholder.com/600.png/654/fff' },
  ];
  slideConfig = { slidesToShow: 1, slidesToScroll: 1, rtl: true };

  addSlide() {
    this.slides2.push({ img: 'http://placehold.it/350x150/777777' });
  }

  removeSlide() {
    this.slides2.length = this.slides.length - 1;
  }

  // slickInit(e) {
  //   console.log('slick initialized');
  // }

  // breakpoint(e) {
  //   console.log('breakpoint');
  // }

  // afterChange(e) {
  //   console.log('afterChange');
  // }

  // beforeChange(e) {
  //   console.log('beforeChange');
  // }

  constructor(private sliderService: SliderService) {}

  ngOnInit() {
    this.sliderService.getSlider('').subscribe(
      (response) => {
        var data = response.data;
        console.log('this.slides==');
        this.slides = response.data;
        console.log(this.slides);
      },

      (error) => {
        console.log(error);
      }
    );
  }
}
