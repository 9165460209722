<section class="section partners  ">
    <div class="container py-5" [class.border-bottom]="bordered">
        <h4 class="bold text-center mb-5 text-secondary">{{ 'Partners' | translate }}</h4>

        <swiper [config]="config" class="swiper-container  pb-5">
            <div class="swiper-wrapper px-1">
                <div class="swiper-slide" *ngFor="let partner of partners">
                    <img src="{{partner.logo}}" class="img-responsive" alt="{{partner.name}}" style="max-height: 120px"/>
                </div>
            </div>
        </swiper>
    </div>
</section>
