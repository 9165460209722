<section class="section mt-6n">
  <div class="container pt-0">
    <div class="shadow bg-contrast p-4 rounded">
      <div class="row gap-y text-center">
        <div
          class="col-12 col-md-4 py-4 px-5 d-flex flex-column"
          [ngClass]="{ 'b-r': i + 1 !== 3 }"
          *ngFor="let block of blocks; let i = index"
        >
          <div class="icon-shape mb-4">
            <img src="assets/img/shps/bullets/{{ i + 1 }}.svg" class="shape shape-xxl icon-xxl" alt="" />

            <div
              class="rounded-circle {{
                block.class
              }} p-3 d-flex align-items-center justify-content-center shadow icon-xl center-xy"
            >
              <img src="assets/img/demo/logo/{{ block.icon }}.svg" class="img-responsive" alt="" />
            </div>
          </div>

          <h4 class="text-darker bold mt-3">{{ block.title }}</h4>

          <p class="mt-4">{{ block.description }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
