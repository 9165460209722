import { Component, OnInit } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'dc-online-payment-features',
  templateUrl: './online-payment-features.component.html',
  styleUrls: ['./online-payment-features.component.scss'],
})
export class OnlinePaymentFeaturesComponent implements OnInit {
  elements = [
    { icon: 'image', title: 'Image Library', url: "https://www.instagram.com/sharefoundation.sy/"},
    { icon: 'video', title: 'Video Library', url: "https://www.youtube.com/channel/UCqp2ZuyX8W5kbtqahXcYCMw"},
    { icon: 'file', title: 'Profile', url: "https://twitter.com/share_Found"},
  ];

  constructor() {}

  ngOnInit() {}
}
