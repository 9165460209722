<section class="section shape-testimonials bg-light">
  <div class="shapes-container">
    <div class="shape shape-triangle"><div></div></div>
  </div>

  <div class="container bring-to-front">
    <div class="row align-items-center">
      <div class="col-md-6 order-sm-last">
        <div class="image-background contain" style="background-image: url(assets/img/shps/map-dots.svg)">
          <div class="user-reviews" id="user-review-nav">
            <!-- <a
              href="javascript:;"
              class="review absolute user nav-item"
              *ngFor="let review of reviews; let i = index"
              [class.active]="i === currentSlide"
              [ngStyle]="{ left: review.position.left, top: review.position.top }"
              [attr.data-step]="i"
              (click)="updateSlider(i)"
            >
              <div class="text">
                <img
                  [src]="'assets/img/avatar/' + (i + 1) + '.jpg'"
                  class="img-responsive shadow rounded-circle"
                  alt="..."
                  data-aos="zoom-in"
                />
              </div>
            </a> -->

            <div
              class="review absolute user nav-item"
              *ngFor="let review of reviews; let i = index"
              [class.active]="i === currentSlide"
              [ngStyle]="{ left: review.position.left, top: review.position.top }"
              [attr.data-step]="i"
              (click)="updateSlider(i)"
            >
              <img
                [src]="'assets/img/avatar/' + (i + 1) + '.jpg'"
                class="img-responsive shadow rounded-circle"
                alt="..."
                data-aos="zoom-in"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card shadow">
          <div class="card-body p-5">
            <swiper [index]="currentSlide" (indexChange)="onIndexChange($event)">
              <div class="swiper-slide" *ngFor="let review of reviews">
                <blockquote>
                  <fa-icon [icon]="'quote-left'"></fa-icon>
                  <p class="lead bold">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                    laudantium,totam rem aperiam.
                  </p>

                  <hr class="w-10 mt-5" />
                  <div class="small">
                    <span class="bold d-block">Jane Doe,</span>
                    Marketing Director
                  </div>
                </blockquote>
              </div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
