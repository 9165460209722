<section class="bg-light b-t">
  <div class="container">
        <div class="section-heading text-center">
            <img width="40px" src="assets/images/sharefoundation-icon.png" />
            <h2 class="bold">{{ 'News' | translate }}</h2>
        </div>
        <div class="row gap-y">
            <div class="col-md-6 col-lg-4" *ngFor="let post of posts">
                <div class="card card-blog shadow-box shadow-hover border-0"><a href="post/{{ post.slugable.key }}"><img class="card-img-top img-responsive" src="{{ post.image | media:4 }}" alt="{{ post.name }}"></a>
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="author d-flex align-items-center"><img class="author-picture rounded-circle icon-md shadow-box" src="https://sharefoundation.co.uk/website/images/fav.png" alt="...">
                                <p class="small bold my-0"></p>
                            </div>
                        </div>
                        <ul class="">
                            <!--<li>-->
                            <!--<a href=""><i class="ti-user"></i></a>-->
                            <!--</li>-->
                            <li class="date">
                                <a href="#"> <i class="ti-calendar"></i>  {{ post.created_at | date}}</a>
                            </li>
                        </ul>
                        <hr>

                        <!--<a href="post/{{ post.slugable.key }}" class="read-more">{{ 'Read More...' | translate }}</a>-->
                        <p class="card-title regular"><a href="post/{{ post.slugable.key }}">{{ post.name }}</a></p>
                    </div>
                </div>
            </div>
        </div>
  </div>
</section>