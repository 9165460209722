import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-home-purchase',
  templateUrl: './home-purchase.component.html',
  styleUrls: ['./home-purchase.component.scss'],
})
export class HomePurchaseComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
