<!-- <div [ngClass]="{ 'nav-item': inNavbar }" ngbDropdown>
  <a *ngIf="inNavbar; else button" id="language-dropdown" class="nav-link" ngbDropdownToggle>
    {{ getLangLabel(currentLanguage) }}
  </a>
  <ng-template #button>
    <button id="language-dropdown" class="btn btn-sm " ngbDropdownToggle>
      {{ getLangLabel(currentLanguage) }}
    </button>
  </ng-template>
  <div ngbDropdownMenu aria-labelledby="language-dropdown" [ngClass]="menuClass">
    <button class="dropdown-item" *ngFor="let language of languages" (click)="setLanguage(language)">
      {{ getLangLabel(language) }}
    </button>
  </div>
</div> -->
