<section class="section">
    <div class="container">
        <div class="section-heading text-center">
            <img width="40px" src="assets/images/sharefoundation-icon.png" />
            <h2 class="bold">{{ 'Our Campaigns' | translate }}</h2>
        </div>

        <nav class="slide nav nav-tabs nav-outlined nav-rounded justify-content-around justify-content-md-center mb-5">
            <a class="theme-btn" href="javascript:;" (click)="PrevSlide()" style="margin-left: 10px">
                {{ 'Prev' | translate }}
            </a> {{ ' '  }}
            <a class="theme-btn" href="javascript:;" (click)="NextSlide()" style="margin-left: 10px">
                {{ 'Next' | translate }}
            </a>
        </nav>

        <swiper [index]="currentSlide">
            <div class="" *ngFor="let campaign of campaignsList; let i = index">
                <div *ngIf="currentSlide === i" class="container-fluid pt-0 pb-4">
                    <div class="row gap-y align-items-center">
                        <div class="col-md-6 order-md-2 ml-md-auto">
                            <div class="browser shadow">
                                <img src="{{ campaign.image }}" alt="{{ campaign.name }}" class="img-responsive"/>
                            </div>
                        </div>

                        <div class="col-md-5 mr-md-auto">
                            <dc-badge [bg]="'contrast'" [text]="'primary'">{{ 'Campaigns' | translate }}</dc-badge>
                            <h2 class="display-4 light campaign-title">{{ campaign.name }}</h2>
                            <p class="text-dark bold">
                                {{ campaign.donation_total | currency: 'USD' }} of {{ campaign.donation_goal | currency:
                                'USD' }} Goal
                            </p>
                            <div class="progress mt-3">
                                <div
                                        class="progress-bar"
                                        style="width: {{ (campaign.donation_total / campaign.donation_goal) * 100 }}%"
                                ></div>
                            </div>

                            <p class="lead">
                                <!--{{campaign.summary}}-->
                            </p>

                            <hr class="mt-5"/>

                            <p class="small text-secondary">
                                {{ campaign.summary }}

                            </p>
                            <a class="theme-btn" href="https://sharefoundation.co.uk/"
                            >{{ 'Donate Now' | translate }}<i class="fa fa-angle-double-right"></i
                            ></a>
                            <a class="theme-btn" href="page/campaign/{{ campaign.slug}}"
                              >{{ 'Learn More' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </swiper>
    </div>
</section>
