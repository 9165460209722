import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';

import {TranslateService} from '@ngx-translate/core';
import {merge} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

import {environment} from '@env/environment';
import {Logger, UntilDestroy, untilDestroyed} from '@core';
import {I18nService} from '@app/i18n';


import AOS from 'aos';

const log = new Logger('App');

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        './app.component.scss',
        // './../assets/css/themify-icons.css',
        // './../assets/css/font-awesome.min.css',
        // './../assets/css/flaticon.css',
        // './../assets/css/odometer-theme-default.css',
        // './../assets/css/bootstrap.min.css',
        // './../assets/css/animate.css',
        //  './../assets/css/owl.carousel.css',
        // './../assets/css/owl.theme.css',
        // './../assets/css/slick.css',
        // './../assets/css/slick-theme.css',
        // './../assets/css/swiper.min.css',
        // './../assets/css/owl.transitions.css',
        // './../assets/css/jquery.fancybox.css',
        //  './../assets/css/f-style.css',
        // './../assets/css/share-style.css',

    ],

})
export class AppComponent implements OnInit, OnDestroy {

    // private metaTagService:Meta;
    private translate:TranslateService;

    constructor(private router:Router,
                private activatedRoute:ActivatedRoute,
                private titleService:Title,
                private translateService:TranslateService,
                private i18nService:I18nService,
                private metaTagService:Meta,) {
        const temp = document.createElement('link');
        if (!localStorage.getItem('language')) {
            localStorage.setItem('language', 'ar');
        }
        // if (localStorage.getItem('language') === 'ar') {
        //     temp.innerHTML = '<link href="/assets/css/ar-style.css" rel="stylesheet" />';
        //     console.log('arabic');
        //
        // } else {
        //     temp.innerHTML = '<link href="/assets/css/share-style.css" rel="stylesheet" />';
        //     console.log('english');
        // }
        const head = document.head;
        while (temp.firstChild) {
            head.appendChild(temp.firstChild);
        }
    }
    ngOnInit() {
        let meta_Keywords = '';
        let meta_Description = '';
        let meta_Author = '';

        if (localStorage.getItem('language') === 'ar') {
            this.titleService.setTitle('مؤسسة شارك');
            meta_Keywords = "مؤسسة شارك ،مؤسسة غير حكومية ، منظمة سورية ، التمكين الاجتماعي ، تمكين المرأة ، التمكين الاقتصادي";
            meta_Description = "مؤسسة سورية مستقلة غير حكومية، مسجلة في تركيا وبريطانيا في 2017 – 2018 تأسست لدعم السوريين المتأثرين بالصراع ضمن مجتمعاتهم و ومساعدتهم ";
            meta_Author = "مؤسسة شارك";
        } else {
            this.titleService.setTitle('Share Foundation');
            meta_Keywords = "Share Foundation, non-governmental, Syrian organization, Social Empowerment, Women Empowerment,Economic Empowerment";
            meta_Description = "Share Foundation is a non-governmental, Syrian organization that is registered in Turkey and UK in November2017";
            meta_Author = "Share Foundation";
        }

        this.metaTagService.addTags([
            {name: 'keywords', content: meta_Keywords},
            {name: 'description', content: meta_Description},
            {name: 'robots', content: 'index, follow'},
            {name: 'author', content: meta_Author},
            {name: 'viewport', content: 'width=device-width, initial-scale=1'},
            {name: 'date', content: '', scheme: 'YYYY-MM-DD'},
            {charset: 'UTF-8'}
        ]);


        // Setup logger
        if (environment.production) {
            Logger.enableProductionMode();
        }

        log.debug('init');

        // Setup translations
        this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

        const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

        // Change page title on navigation or language change, based on route data
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                switchMap((route) => route.data),
                untilDestroyed(this)
            )
            .subscribe((event) => {
                const title = event.title;
                if (title) {
                    this.titleService.setTitle(this.translateService.instant(title));
                }
            });

        AOS.init();
    }

    ngOnDestroy() {
        this.i18nService.destroy();
    }

  
}
