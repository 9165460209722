import {Injectable} from '@angular/core';
import {Campaign} from '../models/campaign';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalConstants} from '../common/global-constants';
@Injectable({
    providedIn: 'root',
})
export class CampaignService {
    private url = GlobalConstants.apiURL + 'campaigns';

    campaign:Campaign = new Campaign();

    constructor(private http:HttpClient) {
    }

    getCampaigns(limit:any):Observable<any> {
        const params = new HttpParams({
            fromObject: {limit: limit},
        });
        var campaign = this.http.get<any>(this.url, {params});
        console.log(campaign);
        return campaign;
    }

    getCampaign(slug:any):Observable<any> {
        return this.http.get<any>(GlobalConstants.apiURL + 'campaign/'+ slug);
       
    }
}
