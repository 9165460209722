import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import {Partner} from '../../../models/partner';

@Component({
  selector: 'dc-slider-icons',
  templateUrl: './slider-icons.component.html',
  styleUrls: ['./slider-icons.component.scss'],
})
export class SliderIconsComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  bordered: boolean = true;

  @Input()
  edge: string;

  @Input()
  background: string;

  @Input()
    partners:Partner[];

  public config: SwiperConfigInterface = {
    // Default parameters
    slidesPerView: 4,
    breakpoints: {
      // when window width is >= 320px
      1024: {
        slidesPerView: 4,
      },
      280: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      820: {
        slidesPerView: 3,
        spaceBetween: 40
      },
    },
    spaceBetween: 30,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    loop: true,
    navigation: false,
    pagination: false,
    observer: true,
  };

  constructor() {}

  ngOnInit() {}
}
