import {Injectable} from '@angular/core';
import {Post} from '../models/post';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalConstants} from '../common/global-constants';
@Injectable({
    providedIn: 'root',
})
export class PostService {
    private url = GlobalConstants.apiURL + 'posts/';

    post:Post = new Post();

    constructor(private http:HttpClient) {
    }

    getPost(slug:any):Observable<any> {
        return this.http.get<any>(this.url + slug);
    }

    getPostsByCategory(cat:any):Observable<any> {
        return this.http.get<any>(
            GlobalConstants.apiURL + 'posts-by-category-slug?cat=' + cat + '&limit=100'
        );
    }

    getLatestPosts(limit:any):Observable<any> {
        return this.http.get<any>(
            GlobalConstants.apiURL + 'latest-posts?limit' + limit
        );
    }

    getPostsByTag(tag:any):Observable<any> {
        return this.http.get<any>('https://backend.sharefoundation.co.uk/api/v1/posts?tags=' + tag);
    }

    getSearch(search:any):Observable<any> {
        return this.http.get<any>('https://backend.sharefoundation.co.uk/api/v1/search?q=' + search);
    }

    getMicrofinancesPosts():Observable<any> {
        return this.http.get<any>(GlobalConstants.apiURL + 'posts-by-category?cat=51&limit=4');
    }

    getProgramsPosts():Observable<any> {
        return this.http.get<any>(GlobalConstants.apiURL + 'posts-by-category?cat=53&limit=4');
    }

    getNewsPosts():Observable<any> {
        return this.http.get<any>(GlobalConstants.apiURL + 'posts-by-category?cat=52&limit=3');
    }

    contact(fields:any):Observable<any> {
        return this.http.post<Response>(GlobalConstants.apiURL + 'contact-send', fields);
    }

    getId(url:string) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    generateIfram(url:string) {
        const videoId = this.getId(url);
        const videoIfram = '<iframe width="560" height="315" src="//www.youtube.com/embed/'
            + videoId + '" frameborder="0" allowfullscreen></iframe>';
        console.log(videoIfram);
        return videoIfram;

    }

    extractUrls(content:string) {
        var finalContent=content;
        var re = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
        //var str = "I am a text and this is a youtube video https://www.youtube.com/watch?v=j82FBbgpUy4 !!!"
        var links = re.exec(content);
        if (links)
            finalContent = content.replace(links[0], this.generateIfram(links[0]));


        console.log(links);
        return finalContent;
    }
}
