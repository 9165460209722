import { Component, OnInit } from '@angular/core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptionsService } from '../../services/theme-options.service';
import { ThemeOptions } from '../../models/themeOptions';
import { GlobalConstants } from '../../common/global-constants';

@Component({
  selector: 'dc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  fa: any = {
    longArrowAltRight: faLongArrowAltRight,
  };
  public themeOptions: ThemeOptions = new ThemeOptions();
  public loader = true;
  constructor(private themeOptionsService: ThemeOptionsService) {}

  ngOnInit() {
    //get theme options
    this.themeOptionsService.getThemeOptions().subscribe(
      (response) => {
        this.themeOptions = response.data; // = response.data;
        GlobalConstants.globalThemeOptions = this.themeOptions;
        //setTimeout(function() {
          this.loader = false;

        //}, 2000);
        //return this.post
      },

      (error) => {
        console.log(error);
      }
    );
  }
  getGlobalThemeOptions() {
    return GlobalConstants.globalThemeOptions;
  }
  closeNavbar() {
    alert(34);
  }
  isArabic() {
    if (localStorage.getItem('language') === 'ar')
      return true;
    return false;
  }

}
