import { Component, Input, OnInit } from '@angular/core';
import { faFacebook, faPinterest, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'dc-footer3',
  templateUrl: './footer3.component.html',
  styleUrls: ['./footer3.component.scss'],
})
export class Footer3Component implements OnInit {
  icons = {
    facebook: faFacebook,
    twitter: faTwitter,
    youtube: faYoutube,
    pinterest: faPinterest,
  };

  @Input() containerClass: string;

  constructor() {}

  ngOnInit(): void {}
}
