import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dc-cta2',
  templateUrl: './cta2.component.html',
  styleUrls: ['./cta2.component.scss'],
})
export class Cta2Component implements OnInit {
  @Input() cssClass: string;
  @Input() containerClass: string;

  constructor() {}

  ngOnInit(): void {}
}
