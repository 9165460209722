import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-start-now-box',
  templateUrl: './start-now-box.component.html',
  styleUrls: ['./start-now-box.component.scss'],
})
export class StartNowBoxComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
