<section class="section bg-contrast edge top-left b-b">
  <div class="container">
    <div class="row">
      <div class="col-md-6 b-md-r">
        <h2>DashCore official <span class="bold">Newsletter</span></h2>
      </div>

      <div class="col-md-5 ml-md-auto">
        <dc-form-register-simple-inline></dc-form-register-simple-inline>
      </div>
    </div>
  </div>
</section>
