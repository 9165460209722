<section class="section bg-light send-and-receive">
  <div class="container bring-to-front">
    <div class="row gap-y">
      <div class="col-md-6" *ngFor="let card of cards">
        <div
          class="rounded bg-contrast shadow-box image-background off-left-background lift-hover p-4 pl-6 pl-md-9"
          [style.background-image]="'url(assets/img/payments/' + card.img + '.svg)'"
        >
          <h3>{{ card.title }}</h3>
          <p class="text-secondary mb-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error eveniet nihil perspiciatis quia quidem quod
            ratione sapiente sint?
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
