import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FaqsService {
  constructor() {}

  getFaqs(): Observable<any> {

    if (localStorage.getItem('language') === 'ar'){
      return of([
        {
          question: 'سياسة الخصوصية',
          answer:
            'اختبار سياسة الخصوصية',
        },
      ]);
    }else{
      return of([
        {
          question: 'What is our services',
          answer:
            'When you buy Dashcore, you get all you see in the demo but the images. We include SASS files for styling, complete JS files with comments, all HTML variations. Besides we include all mobile PSD mockups.',
        },
        
      ]);
    }

    
  }
}
