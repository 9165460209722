import { Component, OnInit } from '@angular/core';

import { environment } from '@env/environment';
import { GlobalConstants } from '../../../../common/global-constants';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  version: string | null = environment.version;

  constructor() {
    if(localStorage.getItem('language')=='en_US')
      this.boxes = [
        {
          icon: 'star',
          title: 'Transparency',
          description:
              'Encouraging communication and building a strong relationship amongst all stakeholders to ensure the greatest transparency.',
        },
        {
          icon: 'users',
          title: 'Empowerment',
          description:
              'Building the capacity of individuals, especially women, to decrease the consequences of protracted crises.',
        },
      ];
    if(localStorage.getItem('language')=='ar')
      this.boxes = [
        {
          icon: 'star',
          title: 'الشفافية',
          description:
              'تشجيع التواصل وبناء علاقة قوية بين جميع أصحاب المصلحة لضمان أكبر قدر من الشفافية.',
        },
        {
          icon: 'users',
          title: 'التمكين',
          description:
              'بناء قدرات الأفراد ، وخاصة النساء ، للحد من عواقب الأزمات التي طال أمدها.',
        },
      ];
  }

  boxes: object[] = [
    {
      icon: 'star',
      title: 'Transparency',
      description:
        'Encouraging communication and building a strong relationship amongst all stakeholders to ensure the greatest transparency.',
    },
    {
      icon: 'users',
      title: 'Empowerment',
      description:
        'Building the capacity of individuals, especially women, to decrease the consequences of protracted crises.',
    },
  ];

  ngOnInit() {}

  getAboutUsText() {
    // about_us='';
    if (GlobalConstants.globalThemeOptions)
       return GlobalConstants.globalThemeOptions.about_us_text;

  }
}
