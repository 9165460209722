<section
  class="section image-background overlay overlay-contrast alpha-5 contain"
  [style.background-image]="'url(assets/img/shps/map-dots.svg)'"
>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-5 mr-md-auto">
        <span class="badge bg-contrast text-primary rounded-pill shadow-sm py-2 px-4"> Customer trust us </span>

        <p class="font-md display-md-1 light m-0 text-darker">
          <span class="counter extra-bold" [countUp]="123">123</span>K
        </p>

        <p class="m-0 font-md text-dark"><span class="bold">Happy</span> Customers</p>

        <hr class="my-4 w-25" />
        <p class="small text-muted">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias atque aut earum esse et exercitationem fuga
        </p>
      </div>

      <div class="col-md-6">
        <dc-feather name="thumbs-up" size="48" iconClass="stroke-primary"></dc-feather>

        <div class="section-heading">
          <h2 class="bold">Building trust around the world</h2>

          <p class="lead text-secondary">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias at commodi cum dignissimos ducimus hic nemo
            quia ut velit.
          </p>
        </div>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate dignissimos dolorem libero officiis quo
          repellendus voluptatibus! A assumenda eius excepturi labore minima nesciunt quod ratione, reiciendis repellat
          sapiente veritatis vero!
        </p>

        <a href="javascript:;" class="btn btn-rounded btn-primary mt-4">Learn more</a>
      </div>
    </div>
  </div>
</section>
