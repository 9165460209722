import { Component, OnInit } from '@angular/core';
import {ProjectService} from '../../../services/project.service';
import {Project} from '../../../models/project';
import {GlobalConstants} from '../../../common/global-constants';
@Component({
  selector: 'dc-slider-bring-to-life',
  templateUrl: './slider-bring-to-life.component.html',
  styleUrls: ['./slider-bring-to-life.component.scss'],
})
export class SliderBringToLifeComponent implements OnInit {
  elements = [
    { label: 'Clean inbox UI design', img: 'inbox', icon: 'mail' },
    { label: 'Outstanding calendar UI design', img: 'calendar', icon: 'calendar' },
    { label: 'Draft purchases with the invoice feature', img: 'invoice', icon: 'file' },
  ];

  currentSlide: number = 0;
  public projects:Project[];
  constructor(private projectService:ProjectService) {
  }

  ngOnInit() {
    // this.projects =this.features;
    this.projectService.getReadyToMarketProjects(4).subscribe(
        (response) => {
          var data = response.data;

          this.projects = response.data;
          console.log(this.projects);
        },
        (error) => {
          console.log(error);
        }
    );
  }

  updateSlider(currentSlide: number) {
    this.currentSlide = currentSlide;
  }

  public onIndexChange(index: number): void {
    this.currentSlide = index;
  }
}
