<section class="section {{ cssClass }}">
  <div class="container bring-to-front" [ngClass]="containerClass">
    <div class="shadow rounded text-center bg-dark p-5">
      <div class="text-contrast">
        <i class="fa fa-heart fa-2x mb-3"></i>
        <h2 class="mb-5 text-contrast">Try Dashcore now... Love it forever!</h2>
        <p class="handwritten highlight font-md">Why wait? Start now!</p>
      </div>

      <a href="#" class="btn btn-success text-contrast btn-rounded mt-4">Buy DashCore on Themeforest</a>
    </div>
  </div>
</section>
