<section class="section">
  <div class="container bring-to-front">
    <div
      class="
        overflow-hidden
        shadow
        rounded
        text-center
        overlay overlay-dark
        alpha-9
        p-5
        text-contrast
        image-background
        cover
      "
      style="background-image: url(https://picsum.photos/350/200/?random&gravity=south)"
    >
      <div class="content">
        <div class="section-heading">
          <dc-feather name="cpu" iconClass="mb-2"></dc-feather>
          <h2 class="bold text-contrast">Discover how DashCore works</h2>
        </div>

        <p class="handwritten highlight font-md">Play the video</p>

        <a href="https://www.youtube.com/watch?v=MXghcI8hcWU" class="btn btn-circle btn-lg btn-contrast">
          <dc-feather [name]="'play'" class="ml-2" stroke-width="1"></dc-feather>
        </a>
      </div>
    </div>
  </div>
</section>
