<!-- ./Page header -->
<header
  class="
    header
    page
    section
    parallax
    image-background
    center-bottom
    cover
    overlay overlay-primary
    alpha-8
    text-contrast
  "
  [style.background-image]="'url(assets/img/bg/grid.jpg)'"
>
  <div class="container overflow-hidden">
    <div class="row">
      <div class="col-md-10 col-lg-8">
        <h1 class="display-4 text-contrast bold">
          Ultimate HTML template <span class="d-block light">for your great product</span>
        </h1>
        <p class="lead">
          Dashcore is an astonishing HTML template to showcase promote and develop your product in the best way
        </p>
        <p class="handwritten font-md highlight px-4">It is free for you</p>

        <nav class="nav mt-5">
          <a href="#" class="nav-link mr-3 btn btn-rounded btn-success"> Start now </a>

          <a [routerLink]="['/pricing']" class="nav-link btn btn btn-rounded btn-contrast">
            <fa-icon [icon]="tag" [class]="'mr-3'"></fa-icon> Plans & pricing
          </a>
        </nav>
      </div>
    </div>
  </div>
</header>
