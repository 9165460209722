import { Component, OnInit } from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { SliderService } from '../../../../services/slider.service';
import { PostService } from '../../../../services/post.service';
import { PartnerService } from '../../../../services/partner.service';
import { Slider } from '../../../../models/slider';
import { Post } from '../../../../models/post';
import { Partner } from '../../../../models/partner';
import { ThemeOptions } from '../../../../models/themeOptions';
import { GlobalConstants } from '../../../../common/global-constants';

@Component({
  selector: 'dc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  private translate: TranslateService;

  private slider = new Slider();
  public partners : Partner[];
  public microfinancesPosts: Post[];
  public programmesPosts: Post[];
  public news: Post[];
  public themeOptions: ThemeOptions = new ThemeOptions();
  
  constructor(private sliderService: SliderService, private postService: PostService,private partnerService:PartnerService
  ,private metaTagService: Meta) {
    if(localStorage.getItem('language')=='en_US')
      this.boxes = [
        {
          icon: 'star',
          title: 'Transparency',
          description:
              'Encouraging communication and building a strong relationship amongst all stakeholders to ensure the greatest transparency.',
        },
        {
          icon: 'users',
          title: 'Empowerment',
          description:
              'Building the capacity of individuals, especially women, to decrease the consequences of protracted crises.',
        },
      ];
    if(localStorage.getItem('language')=='ar')
      this.boxes = [
        {
          icon: 'star',
          title: 'الشفافية',
          description:
              'تشجيع التواصل وبناء علاقة قوية بين جميع أصحاب المصلحة لضمان أكبر قدر من الشفافية.',
        },
        {
          icon: 'users',
          title: 'التمكين',
          description:
              'بناء قدرات الأفراد ، وخاصة النساء ، للحد من عواقب الأزمات التي طال أمدها.',
        },
      ];
  }

  boxes: object[] = [
    {
      icon: 'star',
      title: 'Transparency',
      description:
        'Encouraging communication and building a strong relationship amongst all stakeholders to ensure the greatest transparency.',
    },
    {
      icon: 'users',
      title: 'Empowerment',
      description:
        'Building the capacity of individuals, especially women, to decrease the consequences of protracted crises.',
    },
  ];

  ngOnInit() {
    


    this.sliderService.getSlider('').subscribe(
      (response) => {
        this.slider = response.data; // = response.data;
        //return this.post
      },

      (error) => {
        console.log(error);
      }
    );

    this.postService.getProgramsPosts().subscribe(
      (response) => {
        this.programmesPosts = response.data; // = response.data;
        //return this.post
      },

      (error) => {
        console.log(error);
      }
    );

    this.postService.getNewsPosts().subscribe(
      (response) => {
        this.news = response.data; // = response.data;
        //return this.post
      },

      (error) => {
        console.log(error);
      }
    );

    this.postService.getMicrofinancesPosts().subscribe(
      (response) => {
        this.microfinancesPosts = response.data; // = response.data;
        //return this.post
      },

      (error) => {
        console.log(error);
      }
    );

    this.partnerService.getPartners(5).subscribe(
        (response) => {
          this.partners = response.data; // = response.data;
          //return this.post
        },

        (error) => {
          console.log(error);
        }
    );

  }

  getGlobalThemeOptions() {
    return GlobalConstants.globalThemeOptions;
  }

  getAboutUsText() {
    // about_us='';
    if (GlobalConstants.globalThemeOptions)
       return GlobalConstants.globalThemeOptions.about_us_text;

  }
}
