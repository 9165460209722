import { Component, OnInit } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptionsService } from '../../services/theme-options.service';
import { ProjectService } from '../../services/project.service';
import { ThemeOptions } from '../../models/themeOptions';
import { Project } from '../../models/project';
import { GlobalConstants } from '../../common/global-constants';
import { HelperService } from '../../helpers/helper.service';
import { faFacebook, faPinterest, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';


@Component({
  selector: 'dc-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss'],
})
export class HomeFooterComponent implements OnInit {

  icons = {
    facebook: faFacebook,
    twitter: faTwitter,
    youtube: faYoutube,
    pinterest: faPinterest,
  };


  // public themeOptions = [];
  fa: any = {
    checkCircle: faCheckCircle,
  };
  public p: Project = new Project();
  public projects: Array<Project>;
  public themeOptions: ThemeOptions = new ThemeOptions();

  constructor(
    private projectService: ProjectService,
    private themeOptionsService: ThemeOptionsService,
    public helperService: HelperService
  ) {}

  ngOnInit() {
    this.themeOptions = GlobalConstants.globalThemeOptions;

    GlobalConstants.siteTitle = 'footer title';

    this.projectService.getProjects(4).subscribe(
      (response) => {
        this.projects = response.data;
        // console.log(this.projects);
        // this.p.setName('yyyyyyyyyyyy');
        // console.log('project='+this.p.name);
        // console.log(this.p.getImage(0));
      },
      (error) => {
        console.log(error);
      }
    );


  }

  getGlobalThemeOptions() {
    return GlobalConstants.globalThemeOptions;
  }

  getImageBySize(imagUrlm: any, size: any) {
    return this.helperService.getImage(imagUrlm, size);
  }
}
