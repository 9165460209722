import {Injectable} from '@angular/core';
import {Project} from '../models/project';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalConstants} from '../common/global-constants';
@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    private url = GlobalConstants.apiURL + 'projects';

    project:Project = new Project();

    constructor(private http:HttpClient) {
    }

    getReadyToMarketProjects(limit:any):Observable<any> {
        const params = new HttpParams({
            fromObject: {limit: limit,ready_to_market:1},
        });
        var project = this.http.get<any>(this.url, {params});
        console.log(project);
        return project;
    }
    getProjects(limit:any):Observable<any> {
        const params = new HttpParams({
            fromObject: {limit: limit,ready_to_market:0},
        });
        var project = this.http.get<any>(this.url, {params});
        console.log(project);
        return project;
    }
    getProject(slug: any): Observable<any> {
        return this.http.get<any>(GlobalConstants.apiURL + 'project/'+ slug);
    }
    // getProject(slug:any):Observable<any> {
    //
    //
    //     const params = new HttpParams({
    //         fromObject: {slug: slug},
    //     });
    //     var project = this.http.get<any>(GlobalConstants.apiURL + 'project/?slug=' + slug);
    //     console.log(project);
    //     return project;
    // }
}
