<section class="section">
  <div class="container bring-to-front">
    <div class="row gap-y">
      <div class="col-lg-{{ promo.col }} promo-column" *ngFor="let promo of promos">
        <div
          class="rounded promo-block zoom-background"
          [class.mt-4]="i > 0"
          *ngFor="let item of promo.items; let i = index"
        >
          <div
            class="absolute top right bottom left image-background cover overlay overlay-{{
              item.overlay.color
            }} alpha-{{ item.overlay.alpha }} w-100 h-100"
            [style.background-image]="'url(assets/img/shop/products/' + item.img + '.jpg)'"
          ></div>

          <div class="content position-relative p-4">
            <h4 class="text-{{ item.text.title }} mt-0">{{ item.title }}</h4>
            <p class="text-{{ item.text.description }} mt-0">{{ item.description }}.</p>
            <a href="javascript:;" class="btn btn-contrast">Shop {{ item.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
