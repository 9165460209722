<form *ngIf="!successMsg" class="form form-contact" [formGroup]="uploadForm" (ngSubmit)="onSubmit()" name="form-contact"
      data-response-message-animation="slide-in-up">

    <div class="form-group">
        <label for="name" class="text-dark bold">{{ "Name" | translate }}</label>
        <input
                type="text"
                name="name"
                id="name"
                class="form-control bg-contrast"
                placeholder="{{ 'Name' | translate }}"
                formControlName="name"
                required
        />
    </div>
    <div class="form-group">
        <label for="email" class="text-dark bold mb-0">{{ "Email address" | translate }}</label>
        <small id="emailHelp" class="form-text text-secondary mt-0 mb-2 italic"
        ></small
        >
        <input
                type="email"
                name="email"
                id="email"
                class="form-control bg-contrast"
                placeholder="{{ 'Valid Email' | translate }}"
                formControlName="email"
                required
        />
    </div>

    <div class="form-group">
        <label for="contact_email" class="text-dark bold">{{ "Subject" | translate }}</label>
        <input
                type="text"
                name="subject"
                id="contact_subject"
                class="form-control bg-contrast"
                placeholder="{{ 'Subject' | translate }}"
                formControlName="subject"
                required
        />
    </div>

    <div class="form-group">
        <label for="contact_email" class="text-dark bold">{{ "Message" | translate }}</label>
    <textarea
            name="content"
            id="contact_message"
            class="form-control bg-contrast"
            placeholder="{{ 'What do you want to let us know?' | translate }}"
            rows="8"
            formControlName="content"
            required
    ></textarea>
    </div>

    <div class="form-group">
        <button id="contact-submit" name="submit" type="submit" class="btn btn-primary btn-rounded">{{ "Send Message" |
            translate }}
        </button>
    </div>
</form>
<!--<div *ngIf="successMsg" class="success">-->
<!--{{ 'successMsg' | translate}}-->
<!--</div>-->
<div *ngIf="successMsg" class="response-message">
    <div class="section-heading">

        <p class="font-md m-0"><i class="fas fa-check"></i> {{"Thank you!" | translate}}</p>
        <p class="response">{{"Your message has been send, we will contact you as soon as possible."|translate}}</p>
    </div>
</div>
