<section class="section bg-light edge bottom-right">
  <div class="container">
    <div class="section-heading text-center">
      <fa-icon [icon]="faQuoteRight" size="3x" class="mb-3"></fa-icon>
      <h2 class="bold display-4">Testimonials</h2>
    </div>

    <dc-testimonials-slider></dc-testimonials-slider>
  </div>
</section>
