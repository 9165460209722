import { Component, OnInit, Input } from '@angular/core';

import { I18nService } from '../i18n.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() inNavbar = false;
  @Input() menuClass = '';

  constructor(private i18nService: I18nService) {}

  ngOnInit() {}

  setLanguage(language: string) {
    this.i18nService.language = language;
    window.location.reload();
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
  getLangLabel(label:string) {

    if (this.i18nService.language == 'en_US') {
      if (label == 'ar')
        return 'عربي';
      // if (label == 'tr')
      //   return 'Turkish'
      if (label == 'en_US')
        return 'English';

    }
    if (this.i18nService.language == 'tr') {
      if (label == 'ar')
        return 'عربي'
      // if (label == 'tr')
      //   return 'Turkish'
      if (label == 'en_US')
        return 'English';

    }
    if (this.i18nService.language == 'ar' || this.i18nService.language == 'ar-AR') {
      if (label == 'ar')
        return 'عربي';
      // if (label == 'tr')
      //   return 'Turkish'
      if (label == 'en_US')
        return 'English';
    }

  }
}
