import { Component, OnInit, Input } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'dc-form-register-simple-inline',
  templateUrl: './form-register-simple-inline.component.html',
  styleUrls: ['./form-register-simple-inline.component.scss'],
})
export class FormRegisterSimpleInlineComponent implements OnInit {
  envelope = faEnvelope;

  @Input()
  class: string = '';

  @Input()
  label: string = 'Subscribe Now';

  @Input()
  inputClass: string;

  constructor() {}

  ngOnInit() {}
}
