import { Injectable } from '@angular/core';
import { Slider } from '../models/slider';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../common/global-constants';
@Injectable({
  providedIn: 'root',
})
export class SliderService {
  private url = GlobalConstants.apiURL + 'get-slider';
  slug = 'main-slider';
  slider: Slider = new Slider();

  constructor(private http: HttpClient) {}

  getSlider(slug: any): Observable<any> {
    var slider = this.http.get<any>(this.url);

    return slider;
  }
}
