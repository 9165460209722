export interface PostInterface {
  id: string;
  title: string;
  slug: string;
  name: string;
  url: string;
  description: string;
  content: string;
  image: string;
  categories: [];
  tags: [];
  author: {};
  created_at: string;
  updated_at: string;
}
export class Post implements PostInterface {
  id: string;
  title: string;
  slug: string;
  name: string;
  url: string;
  description: string;
  content: string;
  image: string;
  categories: [];
  tags: [];
  author: {};
  created_at: string;
  updated_at: string;
  isPopular: boolean;
  isFeature: boolean;

  public readFromJson(jsonResponse: any) {
    let object = jsonResponse;
    this.title = object.name;
    this.id = object.id;
    this.content = object.content;
  }
}
