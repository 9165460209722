import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-divider-wave',
  templateUrl: './divider-wave.component.html',
  styleUrls: ['./divider-wave.component.scss'],
})
export class DividerWaveComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
